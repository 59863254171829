<template>
  <div class="container-fuild m-0 p-0">
    <b-modal
      id="two-factor-validation"
      centered
      hide-footer
      no-close-on-backdrop
      size="md"
      title="Verify Your Identity"
    >
      <div class="row">
        <div v-if="!otpSent" class="col-md-12">
          <b-form-group
            id="fieldset-1"
            description="Enter your registered email id."
            label="Email ID"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="emailID"
              trim
              type="email"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-if="otpSent" class="col-md-12">
          <b-form-group
            id="fieldset-2"
            description="Enter OTP."
            label="OTP"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="otp"
              trim
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div
        v-if="isProcessing == 1"
        class="d-flex align-items-center text-danger"
      >
        <strong>Please wait...</strong>
        <b-spinner class="ml-auto"></b-spinner>
      </div>

      <div class="row align-center">
        <div class="col-md-2 col-lg-2 offset-8">
          <b-button
            :disabled="isProcessing == 1"
            class="btn_1 mb_5 "
            @click="close()"
            >Cancel</b-button
          >
        </div>
        <div v-if="!otpSent" class="col-md-2 col-lg-2">
          <b-button
            :disabled="isProcessing == 1"
            class="btn_1 mb_5 "
            style="
            color: #fff !important;
            background: #CF645A !important;
            border: transparent;
          "
            @click="validateOwner()"
            >Proceed</b-button
          >
        </div>
        <div v-if="otpSent" class="col-md-2 col-lg-2">
          <b-button
            :disabled="isProcessing == 1"
            class="btn_1 mb_5 "
            style="
            color: #fff !important;
            background: #CF645A !important;
            border: transparent;
          "
            @click="saleReportDownloadRequest()"
            >Submit</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "RestaurantOwnerValidation",
  props: [
    "owner_id",
    "restaurant_id",
    "restaurant_branch",
    "date",
    "city",
    "payment_type",
    "order_type",
    "employee_type",
    "report_type",
    "active_tab",
    "company"
  ],
  data() {
    return {
      isProcessing: 0,
      emailID: "",
      otpSent: false,
      otp: "",
      srdid: ""
    };
  },
  mounted() {},
  methods: {
    close() {
      this.emailID = "";
      this.otpSent = false;
      this.otp = "";
      this.$bvModal.hide("two-factor-validation");
    },
    validateOwner() {
      if (this.emailID.length <= 0) {
        this.$swal({
          // title: "Sale Report Download Request",
          text: "Please enter your registered email-id .",
          icon: "error"
        });
        return;
      }
      this.isProcessing = 1;
      let formdata = new FormData();
      formdata.append("owner_id", this.owner_id);
      formdata.append("email_id", this.emailID);
      formdata.append("restaurant_id", this.restaurant_id);
      formdata.append("restaurant_branch", this.restaurant_branch);
      formdata.append("date", JSON.stringify(this.date));
      formdata.append("city", this.city.city);
      formdata.append("payment_type", this.payment_type);
      formdata.append("order_type", this.order_type);
      formdata.append("employee_type", this.employee_type);
      formdata.append("report_type", this.report_type);
      formdata.append("active_tab", this.active_tab);
      this.axios
        .post("crm/sales-report/validate-owner", formdata)
        .then(response => {
          this.isProcessing = 0;
          if (response.data.status == 200) {
            this.$swal({
              // title: "Sale Report Download Request",
              text: response.data.message,
              icon: "success"
            });
            this.otpSent = true;
            this.srdid = response.data.data;
          } else {
            this.otpSent = false;
            this.$swal({
              // title: "Sale Report Download Request",
              text: response.data.message,
              icon: "error"
            });
          }
        })
        .catch(error => {
          this.isProcessing = 0;
          this.$swal({
            // title: "Sale Report Download Request",
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    saleReportDownloadRequest() {
      if (this.otp.length > 6 || this.otp.length <= 5) {
        this.$swal({
          // title: "Sale Report Download Request",
          text: "Please enter valid OTP .",
          icon: "error"
        });
        return;
      }
      this.isProcessing = 1;
      let formdata = new FormData();
      formdata.append("otp", this.otp);
      formdata.append("srdid", this.srdid);
      formdata.append("employee_type", this.employee_type);
      this.axios
        .post("crm/sales-report/verify-otp", formdata)
        .then(response => {
          this.isProcessing = 0;
          if (response.data.status == 200) {
            this.close();
            this.$swal({
              title: "Report Download Request",
              text: response.data.message,
              icon: "success"
            });
            this.otpSent = false;
            this.srdid = "";
          } else {
            this.$swal({
              // title: "Sale Report Download Request",
              text: response.data.message,
              icon: "error"
            });
          }
        })
        .catch(error => {
          this.isProcessing = 0;
          this.$swal({
            // title: "Sale Report Download Request",
            text: error.response.data.message,
            icon: "error"
          });
        });
    }
  }
};
</script>
<style scoped></style>
